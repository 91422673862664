import { app } from '@/main'

const toasts = [
    {
        title: 'Success',
        type: 'success'
    },
    {
        title: 'Information',
        type: 'info'
    },
    {
        title: 'Warning',
        type: 'warn'
    },
    {
        title: 'Error',
        type: 'error'
    }
]

export enum Toasts {
    Success,
    Information,
    Warning,
    Error
}

export const showToast = (message: string, type: Toasts = Toasts.Success, time: number = 3000) => app.config.globalProperties.$toast.add({ severity: toasts[type].type, summary: toasts[type].title, detail: message, life: time })
