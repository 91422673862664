import moment from 'moment'
import SQL_WASM from 'sql-wasm';

const getTime = (s: number) => [
    Math.floor(s / 3600000),
    Math.floor(s / 60000) % 60,
    Math.floor(s / 1000) % 60
].map((value: number) => value.toString().padStart(2, '0')).join(':')

const getDateTime = (dateTime: Date, format: string = 'DD.MM.YYYY HH:mm:ss') => moment(dateTime).utcOffset(0).format(format)

const trimText = (text: string, max: number) => `${text.trim().slice(0, max)}${text.trim().length > max ? '...' : ''}`

const parseData = async (data: Uint8Array, type?: string) => {
    const result: Data = []
    switch (type?.toLowerCase()) {
        case 'dtl': {
            let offset = 12

            const getUint8 = () => data[offset++]

            const getUint16 = () => getUint8() | getUint8() << 8

            const getUint32 = () => getUint16() | getUint16() << 16

            const getFloat = () => {
                const buffer = new ArrayBuffer(4)
                const bytes = new Uint8Array(buffer)
                const value = getUint32()
                bytes[0] = (value >> 24) & 0xFF
                bytes[1] = (value >> 16) & 0xFF
                bytes[2] = (value >> 8) & 0xFF
                bytes[3] = value & 0xFF
                return new DataView(buffer).getFloat32(0, false)
            }

            const columns: string[] = Array(getUint8()).fill('')
            offset += (columns.length + 1) * 8 + 3
            columns.forEach((_: string, index: number) => {
                const headerLength = getUint16()
                for (let j = 0; j < headerLength; j++)
                    columns[index] += String.fromCharCode(getUint8())
            })
            while (true) {
                const timeStamp = getUint32()
                const miliseconds = getUint8() * 10
                const dateTime = timeStamp * 1000 + miliseconds;
                if (dateTime)
                    result.push(columns.reduce((obj: Obj, column: string) => obj = {
                        ...obj,
                        [column]: column == 'System' ? getUint16() : getFloat()
                    }, {
                        dateTime,
                    }))
                if (offset >= data.length)
                    break
            }
            break;
        }
        case 'db': {
            const db = new (await SQL_WASM()).Database(Array.from(data));
            const [ { values: format } ] = db.exec("SELECT * FROM data_format");
            const [ d ] = db.exec("SELECT * FROM data");
            if (d)
                d.values.forEach(row => result.push(format.reduce((obj, f, index) => ({ ...obj, [f[1]]: row[index + 2] }), {
                    dateTime: Math.floor(row[1] * 1000),
                })));
            break;
        }
    }
    return result
}

const buttons = (data: Data) => {
    const [_dateTime, ...columns]: string[] = Object.keys(data[0])
    return columns
}

export {
    getTime,
    getDateTime,
    trimText,
    parseData,
    buttons
}
